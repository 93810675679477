import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <h1 className="mb-4">NewRez EZ Hub Demo Placeholder</h1>

    <p className="lead">
      This is a sample application pretending to be NewRez EZ Hub
    </p>
  </div>
);

export default Hero;
