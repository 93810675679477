import React, { Fragment } from "react";

import { Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import contentData from "../utils/contentData";
import { useAuth0 } from "../react-auth0-spa";

const Content = () => {
  const { user } = useAuth0();

  return (
    <div className="next-steps my-5">
      {(user && (
        <Fragment>
          <h2 className="my-5 text-center">Your Applications</h2>
          <Row className="d-flex justify-content-between">
            <Col md={6} className="mb-4 text-right">
              <p>Application 1</p>
            </Col>
            <Col md={6} className="mb-4">
              <p><a href="https://newrez.staging.mhsite.loan/login">Edit Application</a></p>
            </Col>
          </Row>
          <div className="text-center">
            <a href="https://newrez.staging.mhsite.loan/login">
              <Button center color="primary">Start New Application</Button>
            </a>
          </div>
        </Fragment>
      )) || (
        <div>
          <h3 className="my-5 text-center">
            Log in above to see your applications
          </h3>
          <div className="text-center">
            <a href="https://newrez.staging.mhsite.loan/">
              <Button center color="primary">Start Application</Button>
            </a>
          </div>
        </div>
        )}
    </div>
  );
};

export default Content;
